const URL = "/api/v1/";

export const ENDPOINTS = {
  cards: {
    default: URL + 'card',
    cardLink: URL + 'card/card_link',
    views: URL + 'card/views/count',
    status: URL + 'card/status',

  },
  user: {
    default: URL + 'user',
    resetPassword: URL + 'user/reset_password',
    getAuthInfo: URL + 'user/getAuthInfo',
    termsAndConditions: URL + 'user/update_terms_status',
    generateVerifyUserEmail: URL + 'user/email_verification',
    verifyUserEmail: URL + 'profile/email_verify'
  },
  auth: {
    login: URL + 'auth/login',
    register: URL + 'auth/register',
    forgotPassword: URL + 'auth/reset_password',
    refreshToken: URL + 'auth/refresh_token'
  },
  profile: {
    default: URL + 'profile',
    count: URL + 'profile/count'
  },
  enquiry: {
    default: URL + 'enquiry'
  },
  payments: {
    default: URL + 'payment'
  },
  serviceDesk: {
    default: URL + 'service_desk',
    create: URL + 'service_desk/create',
    list: URL + 'service_desk/list',
    modify: URL + 'service_desk/modify_status',
    serviceList: URL + 'service_desk/service_list',
  },
  connections: {
    status: URL + 'connection/status',
    connect: URL + 'connection/connect',
    list: URL + 'connection/list',
    remove: URL + 'connection/remove'
  }
};
